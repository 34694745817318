import React, { Component } from "react"
import { graphql } from "gatsby"
// Components
import GA from "../components/ga"
import SEO from "../SEO"
import Layout from "../components/layout"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "./scss/pa.module.scss"

class Accessibility extends Component {
  render() {
    const { company, siteUrl, email } = this.props.data.site.siteMetadata
    return (
      <>
        <SEO title="Accessibility" />
        <GA />
        <Layout>
          {/* <SidebarCart /> */}
          <section className={`section ${styles.pa}`}>
            <article className={styles.container}>
              <h1>Accessibility Statment</h1>
              <p>
                {company} is committed to facilitating the accessibility and
                usability of its Web site, {siteUrl}, for all people with
                disabilities. {company} has implemented and will continue to
                implement the relevant portions of the World Wide Web
                Consortium’s Web Content Accessibility Guidelines 2.1 Level AA
                (WCAG 2.1 AA) or higher as its web accessibility standard.
              </p>
              <p>
                Please be aware that our efforts are ongoing. If at any time you
                have specific questions or concerns about the accessibility of
                any web page on {siteUrl}, please contact us at {email}. If you
                do encounter an accessibility issue, please be sure to specify
                the web page in your email, and we will make all reasonable
                efforts to make that page accessible for you.
              </p>
            </article>
          </section>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query Accessibility {
    site {
      siteMetadata {
        company
        siteUrl
        email
      }
    }
  }
`

export default Accessibility
